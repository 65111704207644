<template>
    <Header></Header>
    <div class="people" v-if="isRight">
        <div class="ProfileHeader-wrapper">

            <div class="ProfileHeader-main">
                <div class="ProfileHeader-avatar">
                    <div class="UserAvatar">
                        <img  :src="currentUser?.picUrl" alt="" style="height:90px;width:90px;">
                    </div>
                </div>
                <div class="ProfileHeader-content">
                    <div class="ProfileHeader-contentHead">
                        <h1 class="ProfileHeader-title">
                            <span class="ProfileHeader-name">{{currentUser?.userName}}</span>
                        </h1>
                    </div>
                    <!-- <div  style="overflow: hidden; transition: height 300ms ease-out 0s; height: 25px;">
                        <div >
                            <span>截止时间：{{currentUser?.deadline?parseTime(new Date(currentUser?.deadline),"{y}-{m}-{d} {h}:{i}:{s}"):'无限期'}}</span>
                        </div>
                    </div> -->
                    <div class="ProfileHeader-contentFooter">
                        <div class=" ProfileHeader-buttons">
                            <el-button type="primary" @click="handleSelect('/user-center','usercenter')" size="small" round>编辑个人资料</el-button>
                        </div>
                    </div>
                </div>
        
            </div>
        </div>
        <div class="n-inner">
            <template v-for="item in menuList" :key="item.name"   >
                <div v-if='item.isRight' class="n-btn" @click.prevent="handleSelect(item.path,item.name)">
                    <i class="iconfont" :class="item.meta.icon" :style="{color:item.color}"></i>
                    <span>{{item.meta.title}}</span>
                    <span class="n-num">{{item.num}}</span>
                </div>
            </template>
        </div>
        <div class="col-full">
            <router-view ></router-view>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import {colors} from '@/utils'
import {getNumOfUserFavorites,getNumOfUserEcoTheme,getNumOfUserFollow} from '@/http/basicsApi.js'
import {getUserRightFun} from '@/http/basicsApi'
import {ref } from '@vue/reactivity'
import {parseTime} from '@/utils'
import { useRoute, useRouter } from 'vue-router'
export default {
    components:{
        Header
    },
    setup() {
        const route=useRoute();
        const router=useRouter();
        let isRight=ref(false);
        let menuList=ref([]);
        let currentUser=ref(JSON.parse( localStorage.getItem('currentUser')));//当前用户信息
        
        //判断是否有权限访问此页面
        const getRightFun=async()=>{
            console.log('sss');
             console.log(route.name);

            isRight.value=  await getUserRightFun(route.name,0);
            console.log(isRight.value);
            if(isRight.value){
                const menuData=router.getRoutes()
                //菜单
                menuList.value=menuData.find(t=>t.name.toLowerCase()=='people').children
                .sort(function (a, b) {//升序
                        if(a.meta.sort==null || b.meta.sort==null) return -1
                        return a.meta.sort - b.meta.sort;
                    });
                console.log(menuList);
                for(let index = 0; index < menuList.value.length; index++) {
                    let el=menuList.value[index];
                    el.color=colors[index];
                    el.num=0;
                    el.isRight=true;//是否有权限
                    if(el.name.toLowerCase()=="collections"){
                        
                        //获取收藏夹的数量
                        getNumOfUserFavorites(currentUser.value?.userId).then((res)=>{
                            //console.log(res);
                            if(res.code==20000){
                                el.num=res.data;
                            }
                        })
                    }
                    else if(el.name.toLowerCase()=="areaeco"){
                        //判断通用区域经济是否有权限，如果没有权限则隐藏菜单
                        if(await getUserRightFun(el.name,0,'',1)){
                            //获取通用区域经济主题的数量
                            getNumOfUserEcoTheme(currentUser.value?.userId,1).then((res)=>{
                                //console.log(res);
                                if(res.code==20000){
                                    el.num=res.data;
                                }
                            });
                        }
                        else{
                            el.isRight=false;
                        }
                        
                    }
                    else if(el.name.toLowerCase()=="areaecoself"){
                        //获取个性区域经济主题的数量
                        getNumOfUserEcoTheme(currentUser.value?.userId,2).then((res)=>{
                            //console.log(res);
                            if(res.code==20000){
                                el.num=res.data;
                            }
                        });
                    }
                    else if(el.name.toLowerCase()=="follow"){
                        //获取关注的企业数量
                        getNumOfUserFollow(currentUser.value?.userId).then((res)=>{
                            //console.log(res);
                            if(res.code==20000){
                                el.num=res.data;
                            }
                        });
                    }
                }
            }
        }
        //功能菜单的选择事件
        const handleSelect=async (path,name)=>{// eslint-disable-line no-unused-vars
            if(await getUserRightFun(name,0)){
                router.push(path)
            }
        }
        //初始化，
        //判断是否有权限访问此页面
        getRightFun();
        return {
            isRight,
            menuList,
            parseTime,
            currentUser,
            handleSelect,
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.people{
    margin: 60px;
}
.ProfileHeader-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
}
.ProfileHeader-main {
    margin: 0 20px 24px;
    margin-bottom: 0px;
    position: relative;
}
.ProfileHeader-avatar {
    position: absolute;
    top: -14px;
    left: 0;
    z-index: 1;
}
.UserAvatar {
    border: 4px solid #fff;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    background-color: #fff;
    border-radius: 50px;
}
.ProfileHeader-content {
    padding-top: 16px;
    padding-left: 32px;
    margin-left: 94px;
}
.ProfileHeader-contentHead {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 16px;
}
.ProfileHeader-name {
    line-height: 30px;
    font-size: 26px;
    font-weight: 600;
    font-synthesis: style;
}
.ProfileHeader-buttons {
    position: absolute;
    right: 0;
    bottom: 10px;
}
.ProfileHeader-contentFooter {
    position: relative;
    padding-top: 8px;
}
.n-inner {
    height: 66px;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 0 0 2px 2px;
    padding: 0 20px;
    border-top: 1px solid $bordercolor;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;
}
.n-btn {
    display: inline-block;
    line-height: 64px;
    height: 64px;
    margin-right: 16px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
}
.n-btn i{
    margin-right: 4px;
}
.n-num {
    margin-left: 5px;
    font-size: 12px;
    color: #99a2aa;
    font-family: Arial;
}
// .n-btn.active {
//     color: #00a1d6;
// }
.col-full {
    background: #fff;
    border: 1px solid $bordercolor;
    border-radius: 2px;
}
.n-inner a.router-link-exact-active,.n-btn:hover{
    color: $specialtext-color;
    border-bottom: 2px solid $specialtext-color;
}
</style>